import { merge } from 'lodash';

export function extendConfiguration(environment: any) {
  return new Promise<void>((resolve, reject) => {
    const httpRequest = new XMLHttpRequest();
    httpRequest.onreadystatechange = () => {
      if (httpRequest.readyState === XMLHttpRequest.DONE) {
        if (httpRequest.status === 200) {
          const environmentSettings = JSON.parse(httpRequest.responseText);
          merge(environment, environmentSettings);
          resolve();
        } else {
          reject(new Error('there was a problem with loading the environment settings'));
        }
      }
    };
    httpRequest.open('GET', 'assets/settings.json');
    httpRequest.send();
  });
}
