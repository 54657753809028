import { commonEnv } from './environment.common';

export const environment = {
  ...commonEnv,
  environmentLabel: null,
  production: true,
  version: '1.10.60',
  commitHash: '82e92a5d4fc5e00224526ade1e58d4fa1aa70fab',
  sentry: '',
  boomerangApiUrl: 'http://localhost:5001',
  auth: {
    serverUrl: 'https://auth.cloud.triarc-labs.com/auth/',
    clientId: 'boomerang',
    bearerExcludedUrls: ['http://localhost:4201'],
    scope: 'openid email profile offline_access',
    twoFaTimeLimit: 120,
    logoutUrl: 'http://localhost:4201',
  },
};
